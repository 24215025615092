/* @media (max-width: 1280px) {
    .content{
        display: none;
    }
} */

@media (max-width: 1224px) {
    .mobile-view{
        display: none !important;
    }
}

@media (min-width: 1224px) {
    .category{
        display: contents;
    }
}

@media (max-width: 1024px) {
    .category{
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
    }
}

@media (min-width: 1024px) {
    .concern{
      display: none;
      /* display: inline-flex;
      width: 100%;
      justify-content: space-between; */
    }
}

@media (max-width: 1024px) {
    .concern{
      display: inline-flex;
      width: 100%;
      justify-content: center;
    }
}

@media (max-width: 1024px) {
    .showconcern{
      display: none;
    }
}

@media (max-width: 1024px) {
    .view{
        display: none !important;
    }
}
@media (min-width: 1024px) {
    .content-small{
        display: none !important;
    }
}
/* For small screens */
@media (max-width: 640px) {
    .get-unlimited-access {
      position: relative;
      display: flex;
      align-items:flex-start /* Center the content vertically */
    }
    .arrow-icon {
      width: 15px; /* Adjust the width as needed */
    }
  }
  

  /* For medium and larger screens */
  @media (min-width: 641px) {
    .get-unlimited-access {
      position: relative;
      display: flex;
      align-items: center; /* Center the content vertically */
    }
    .arrow-icon {
      width: 19px; /* Adjust the width as needed */
    }
  }