@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Groteska';
  src: url('./assets/Fonts/FontsFree-Net-Groteska-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Groteska';
  font-weight: bold;
  src: url('./assets/Fonts/FontsFree-Net-Groteska-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Groteska';
  font-weight: 900;
  src: url('./assets/Fonts/FontsFree-Net-Groteska-Heavy.ttf') format('truetype');
}

.regular-font-heading {
  font-family: 'Groteska', sans-serif;
}
.bold-font-heading {
  font-family: 'Groteska', sans-serif;
  font-weight: bold;
}
.heavy-font-heading {
  font-family: 'Groteska', sans-serif;
  font-weight: 900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h-42rem {
  height: 42rem;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.polygonWrap {
  -webkit-clip-path: polygon(0% 0%, 83% 0, 100% 50%, 83% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 83% 0, 100% 50%, 83% 100%, 0% 100%);
}
@media (max-width: 767px) {
  .polygonWrap {
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 83%, 50% 100%, 0 83%);
    clip-path: polygon(0% 0%, 100% 0, 100% 83%, 50% 100%, 0 83%);
  }
}