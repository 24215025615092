.inner-shadow-signup  {
  background: #394a59 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
}

.background {
  background: #424b47 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000004d;
}

.height body {
  background-color: #36403c;
}

.border-bottom {
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: #36403c;
}

.focus input[type="text"]:focus {
  border: 3px solid #555;
}

.focus :focus-visible input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.width {
  width: 65%;
}

@media (max-width: 1070px) {
  .align {
    margin-right: 0px;
  }
}

@media (max-width: 1279px) {
  .border-bottom {
    width: 15rem;
  }
}

@media (max-width: 768px) {
  .border-bottom {
    width: 11rem;
  }
}

@media (max-width: 1280px) {
  .img {
    height: 427px;
  }
}

@media (max-width: 1024px) {
  .display {
    display: none;
  }
}

.ant-picker-input{
    padding: 10px;
    border: none;
    border-radius: 0px;
    

}
  


  
