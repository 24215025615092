@media (max-width: 640px) and (min-width: 480px){
    .desc-div {
        padding-left: 1rem;
        padding-top: 3rem;
        width: 36%;
    }
    .banner-hr {
        margin-top: 3px;
        width: 90%;
    }
    .banner-desc {
        width: 100%;
        font-size: 10px;
    }
    .main-text {
        font-size: 30px;
    }
    .c-h-24rem{
        height: 24rem;
    }
}

@media (max-width: 480px) and (min-width: 300px){
    .desc-div {
        padding-left: 0rem;
        padding-top: 3rem;
        width: 40%;
    }
    .banner-hr {
        margin-top: 3px;
        width: 90%;
    }
    .banner-desc {
        width: 100%;
        font-size: 10px;
    }
    .main-text {
        font-size: 24px;
    }
}