@media (max-width: 640px) and (min-width: 375px) {
    .c-hidden {
        display: none !important;
    }
}

.bar-line-1{
    border-top: 2.5px solid #FFFFFF;
    width: 24px;
}

.bar-line-2{
    border-top: 2.5px solid #FFFFFF;
    width: 16px;
    margin-left: 4px;
    margin-top: 2px;
}

.slider-div .slick-prev,
.slider-div .slick-next {
  display: none !important;
}