.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-align-webkit-center {
  text-align: -webkit-center;
}

.line-height-initial {
  line-height: initial;
}

.body-scans-image {
  /* background-size: 100% 100%; */
  background-position: unset;
  background-size: cover;
}

.sticky {
  position: sticky;
  top: 170px;
  z-index: 20;
  height: 100%;
}

.main-section {
  /* height: 130vh; */
}

.absolute.transform {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
}

.loader-div {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;
}

.pagination .ant-select-selector {
  color: gray !important;
  border: 1px solid gray;
  background-color: #36403c00 !important;
  border: 1px solid gray !important;
}

.pagination a {
  color: gray !important;
}

.pagination .ant-pagination-item {
  border: 1px solid gray !important;
}

.pagination .ant-pagination-item-link,
.ant-select-arrow {
  color: gray !important;
}

.chatbot .loader-div {
  width: 100%;
  height: calc(100% - 70px);
  background: rgba(0, 0, 0, 0.2);
  left: 10px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 10px;
}

.botnames {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.productTitle{
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;   
}

.react-datepicker-wrapper {
  width: 100%;
}