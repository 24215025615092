.primary-shadow{
    background: transparent linear-gradient(180deg, #A7636B00 0%, #A7636B 100%) 0% 0% no-repeat padding-box;
}
.secondary-shadow {
    background: transparent linear-gradient(180deg, #A7636B00 0%, #82938C 100%) 0% 0% no-repeat padding-box;
}
.tertiary-shadow {
    background: transparent linear-gradient(180deg, #A7636B00 0%, #394A59 100%) 0% 0% no-repeat padding-box;
}
.analogous-shadow {
    background: transparent linear-gradient(180deg, #A7636B00 0%, #887730 100%) 0% 0% no-repeat padding-box;
}
.warm-shadow {
    background: transparent linear-gradient(180deg, #A7636B00 0%, #9C92A3 100%) 0% 0% no-repeat padding-box;
}
.cool-shadow {
    background: transparent linear-gradient(180deg, #A7636B00 0%, #36403C 100%) 0% 0% no-repeat padding-box;
}

.card-img{
    margin: -110px auto;
}
.image{
    margin-block-start: -31px;
}