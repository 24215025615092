.player{
    position: absolute;
    top: 0;
    z-index: 1;
    object-fit: cover;
    width: 100%, 

}

.slider-div .slick-prev,
.slider-div .slick-next {
  display: none !important;

}

.select {
    gap: 40px !important;

}