@media (max-width: 640px) and (min-width: 480px){
    .c-text1 {
    font-size: 12px;
    }
    .c-text2 {
    font-size: 10px;
    }
    .product-image {
        height: 200px;
    }
    .c-px-16 {
        padding-left: 16px;
        padding-right: 16px;
        font-size: 12px;
    }
    .arrow-up-right-icon{
        height: 10px;
    }
}

@media (max-width: 480px) and (min-width: 370px){
    .c-text1 {
    font-size: 20px;
    }
    .c-text2 {
    font-size: 12px;
    }
    .product-image {
        height: 222px;
    }
    .category-name{
        font-size: 20px;
    }
    .category-name a:first-child{
        font-size: 26px;
    }
    .c-view-all-products{
        font-size: 12px;
        font-weight: 400;
    }
}

@media (max-width: 369px) and (min-width: 300px){
    .category-name{
        font-size: 20px;
    }
    .product-image {
        height: 222px;
    }
}

@media (max-width: 540px) and (min-width: 480px){
    .category-name{
        font-size: 26px;
    }
}

@media (max-width: 2000px) and (min-width: 1650px){
    .c-text1 {
    font-size: 22px;
    }
    .c-text2 {
    font-size: 15px;
    }
    .product-image {
        height: 300px;
    }
    .product-price {
        font-size: 20px;
    }
}

@media (max-width: 1650px) and (min-width: 1536px){
    .get-unlimited-access {
        margin-left: 36px;
        padding-left: 18px;
        padding-right: 18px;
    }
    .proceed-to-checkout {
        padding-left: 18px;
        padding-right: 18px;
    }
}

@media (max-width: 1023px) and (min-width: 375px){
    .shop-topbar-position-absolute {
        position: absolute;
    }
    .c-margin-top-15px {
        margin-top: 15px;
    }
    .c-margin-left-4px {
        margin-left: 4px;
    }
}

.slider-div .slick-track{
    padding: 10px 0;
}

.slider-div .slick-prev,
.slider-div .slick-next {
  display: none !important;
}

li>ul                 { transform: translatex(100%) scale(0) }
li:hover>ul           { transform: translatex(101%) scale(1) }
li > button svg       { transform: rotate(270deg) }