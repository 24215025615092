.color{
    color: #394A59 !important;
    border-color: #394A59 !important;
}

.rotate-10{
    transform: rotate(10deg);
}

.stroke-4px{
    stroke-width: 4px;
}

.quantity-value{
    font-size: 25px;
}

.disable{
    background-color: #D9D9D9 !important;
}

.product-img-slider .slick-list {
    height: 100%;
}
.product-img-slider .slick-track {
    height: 100%;
}
.product-img-slider .slick-slide > div{
    height: 100%;
}

@media (min-width: 1630px) {
    .prize{
        font-size: 15px;
    }
    .amount{
        font-size: 15px;
    }
}

@media (min-width: 1280px) and (max-width: 1450px) {
    .prize{
        font-size: 12px;
    }
    .amount{
        font-size: 12px;
    }
}

@media (min-width: 1451px) and (max-width: 1629px) {
    .prize{
        font-size: 13px;
    }
    .amount{
        font-size: 13px;
    }
}

@media (max-width: 1279px) {
    .prize{
        font-size: 15px;
    }
    .amount{
        font-size: 15px;
    }
}