.item-center {
  align-items: center;
}

@media (max-width: 1720px) and (min-width: 1536px) {
  .buy-once-btn p {
    margin-left: 37px !important;
  }
  .c-check-css {
    margin-top: 5px !important;
  }
  .c-product-detail-add {
    padding-left: 14px !important;
  }
}

@media (max-width: 640px) {
  .c-product-detail-div-one {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 18px;
  }
  .know-more {
    display: none;
  }
}

.custom-prevArrow {
  position: absolute;
  left: 0;
  top: 45%;
  z-index: 1;
  color: #a8a8a8;
}

.custom-nextArrow {
  position: absolute;
  right: 0;
  top: 45%;
  color: #a8a8a8;
}

.table-border {
  border: 1px solid;
  padding: 10px 0px;
  text-align: center;
}

.top-border {
  border-top: none;
}

.rotate {
  transform: rotate(-90deg);
}

.border-top {
  border-right: 1px solid;
  padding: 15px 0px;
  /* margin-bottom: 0px; */
}

.backgroundset {
  background: #ffffff 0% 0% no-repeat padding-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

@media (max-width: 600px) {
  .display {
    display: -webkit-inline-box;
  }
}

@media (max-width: 600px) {
  .display {
    display: -webkit-inline-box;
  }
}

@media (max-width: 450px) {
  .expand {
    margin-left: -22px;
  }
}

@media (max-width: 450px) {
  .collaps {
    margin-left: -28px;
  }
}
